import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getUserToken, getUser } from '@/utils/localData'
import { INVITED } from '@/utils/constant'
import { useDispatch } from 'react-redux'
import { getPieceSetting } from '@/redux/modules/piecefolio'

function RequireAuth() {
  const dispatch = useDispatch()

  const token = getUserToken()
  const user = getUser()
  let location = useLocation()

  useEffect(() => {
    const controller = new AbortController()
    const fetchData = async () => {
      try {
        await dispatch(getPieceSetting({ signal: controller.signal })).unwrap()
      } catch (error) {
        console.error('Failed to fetch piece setting:', error)
      }
    }

    fetchData()

    return () => {
      controller.abort()
    }
  }, [])

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' replace state={{ from: location }} />
  }

  if (user && user.status == INVITED)
    return <Navigate to={`/login`} replace state={{ from: location }} />

  return <Outlet />
}

export default RequireAuth
