import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PieceTable from './PiecefolioTable'
import styles from './piecefolio.module.css'
import { useNavigate } from 'react-router-dom'
import { getAllPiece, clearState, pieceSelector, savePieceSettingId } from '@/redux/modules/piecefolio'
import { useSelector, useDispatch } from 'react-redux'
import { Loading } from '@/components'
import { transformErrors } from '@/utils/errorHandling'
import toast from 'react-hot-toast'
import { scrollTop } from '@/utils/helper'
import { getUser } from '@/utils/localData'
import { useLocation } from 'react-router-dom'

function index() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);

  const pieceSettingId = searchParams.get('piece_setting_id');

  const { list, isFetching, isError, errors, successMessage } = useSelector(pieceSelector)

  useEffect(() => {
    let isApiSubscribed = true

    const fetchPiece = () => {
      if (isApiSubscribed) {
        dispatch(getAllPiece({ piece_setting_id: pieceSettingId }))
        dispatch(savePieceSettingId(Number(pieceSettingId)))
        isApiSubscribed = false
      }
    }
    scrollTop()
    fetchPiece()
    return () => {
      isApiSubscribed = false
    }
  }, [pieceSettingId])

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.fieldName !== 'status') toast.error(er.message)
      })
      dispatch(clearState())
    }

    if (successMessage) {
      toast.success(successMessage)
      dispatch(clearState())
    }
  }, [isError, successMessage])

  if (isFetching) return <Loading />
  if (!list) return null
  return (
    <Box className={`mid-container w-100`}>
      <Box className={'header-page-table'}>
        <label className='color-theme title-page-table'>PieceFolio</label>
        <Button
          className={`${styles.btnAddPiece} background-theme background-theme-hover`}
          variant='contained'
          onClick={() => navigate('add-new')}
        >
          新規調査
        </Button>
      </Box>
      <div>
        {Object.keys(list)
          .reverse()
          .map((key) => {
            const dataList = list[key].length ? [...list[key]].reverse() : []
            return <PieceTable key={`piece-${key}`} data={dataList} title={key} />
          })}
      </div>
    </Box>
  )
}

export default index
