import React, { useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { chartTreeMapIcon, squareIcon } from '@/utils/images'
import styles from './search.module.css'
import Skeleton from '@mui/material/Skeleton'
import TextTruncate from 'react-text-truncate'

function Sources({ loading = false, data = [], animation, isSelected }) {
  const ref = useRef(null)

  return (
    <Box ref={ref} className={styles.boxSources}>
      <Box className={styles.boxTitle}>
        <img src={chartTreeMapIcon} />
        リソース一覧
      </Box>
      <Box className={styles.boxContent}>
        {loading ? (
          <>
            <Skeleton variant='rounded' width={170} height={140} sx={{ margin: '0 10px' }} />
            <Skeleton variant='rounded' width={170} height={140} sx={{ margin: '0 10px' }} />
            <Skeleton variant='rounded' width={170} height={140} sx={{ margin: '0 10px' }} />
            <Skeleton variant='rounded' width={170} height={140} sx={{ margin: '0 10px' }} />
            <Skeleton variant='rounded' width={170} height={140} sx={{ margin: '0 10px' }} />
          </>
        ) : (
          data.map((source, index) => {
            return (
              <Source key={`source-${index}`} data={source} index={index} animation={animation} isSelected={isSelected}/>
            )
          })
        )}
      </Box>
    </Box>
  )
}

const Source = ({ data, index, onClick = () => {}, animation, isSelected }) => {
  const ref = useRef(null)
  const { title, link, image } = data
  let domain = new URL(link)
  domain = domain.hostname.replace('www.', '')

  useEffect(() => {
    if (animation) {
      const timer = index * 300
      const timeout = setTimeout(() => {
        ref.current.style.opacity = 1
      }, timer)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      ref.current.style.opacity = 1
    }
  }, [index, animation])

  return (
    <a
      ref={ref}
      href={link}
      className={`${styles.linkCard}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Card onClick={onClick} className={`${styles.cardSource}`} sx={{ width: '10.6rem' }}>
        {title ? (
          <CardContent className={`${styles.cardContent} ${isSelected ? styles.isSelected : ""}`}>
            <TextTruncate line={2} element='p' truncateText='…' text={title} />
          </CardContent>
        ) : (
          <CardContent className={styles.emptyContent}>
            <img src={squareIcon} className={styles.sourceIcon} />
            <img src={squareIcon} className={styles.sourceIcon} />
            <img src={squareIcon} className={styles.sourceIcon} />
            <img src={squareIcon} className={styles.sourceIcon} />
          </CardContent>
        )}
        <CardActions className={`${isSelected ? styles.isSelected : ""}`}>
          <Box className={`${styles.sourceLink}`}>
            <img src={image || squareIcon} className={styles.sourceIcon} />
            <p className='color-theme truncate' style={{ whiteSpace: 'nowrap' }}>
              {domain}
            </p>
            <span className={styles.sourceIndex}>{index + 1}</span>
          </Box>
        </CardActions>
      </Card>
    </a>
  )
}

export default Sources
