import React, { useState, useEffect, useRef  } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {
  ACTIONS,
  EXPORTS,
  EXPORTS_TYPE,
  PERMISSION_VIEW,
  FORMAT_DATE_CAPITAL,
  BENCH_MARK_BUSINESS,
} from '@/utils/constant'
import { ConfirmDialog, ExportPopup } from '@/components'
import { ReactSVG } from 'react-svg'
import { searchAlt2Icon } from '@/utils/images'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  clearPiece,
  pieceSelector,
  getMembersToShare,
  getSharedMembers,
  getPieceWithLinks,
  deletePiece,
  clearState,
  regeneratePieceLinks
} from '@/redux/modules/piecefolio'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import CircularProgress from '@mui/material/CircularProgress'
import { NOT_FOUND } from '@/utils/message'
import styles from './piecefolio.module.css'
import Sharing from './Sharing'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { transformErrors } from '@/utils/errorHandling'
import { BENCHMARK_NOT_EXIT } from '@/utils/message'
import toast from 'react-hot-toast'
import Button from '@mui/material/Button'
import Backdrop from '@mui/material/Backdrop'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
import templateApi from '@/api/template';
import { onDownloadBenchmark } from '@/utils/function'

const Actions = ({ data, onToggleDrawer, drawerOpen }) => {
  const dispatch = useDispatch()
  const { isProcessing } = useSelector(pieceSelector)

  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownRef = useRef(null);

  const onToggleSharingDialog = (e) => {
    setShowSharingDialog(!showSharingDialog)
  }

  const handleCloseSharingDialog = (e) => {
    e.stopPropagation()
    setShowSharingDialog(false)
  }

  const handleDelete = () => {
    dispatch(deletePiece({ piece_id: data.piece_id, key: "" }))
  }

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setDropdownOpen(!dropdownOpen)
  }

  const handleDropdownItemClick = (exportLabel) => () => {
    setDropdownOpen(false);
    onToggleDrawer(true, exportLabel)();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    if (showSharingDialog) {
      dispatch(getMembersToShare({ piece_id: data.piece_id }));
      dispatch(getSharedMembers({ piece_id: data.piece_id }));
    }
  }, [showSharingDialog]);

  return (
    <Box className={`box-actions ${drawerOpen ? 'drawerOpen' : ''}`}>
      <ul className='bg-theme-hover-opacity'>
        {data.share_permission != PERMISSION_VIEW && (
          <li onClick={onToggleSharingDialog} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[0].icon} />
              <p>共有</p>
            </Box>
          </li>
        )}

        <li className={`action`}>
          <div ref={dropdownRef}>
            <Box className={`box-action`} onClick={toggleDropdown}>
              <img src={ACTIONS[1].icon} />
              <p>出力</p>
            </Box>
            {dropdownOpen && (
              <ul className={`${styles.dropdownMenu} ${data.share_permission != PERMISSION_VIEW ? styles.dropdownMenuShifted : styles.dropdownMenuAligned}`}>
                <li onClick={handleDropdownItemClick(EXPORTS[0].label)}>
                  <img src={EXPORTS[0].icon} />
                  <p>{EXPORTS[0].label}</p>
                </li>
                <li onClick={handleDropdownItemClick(EXPORTS[1].label)}>
                  <img src={EXPORTS[1].icon} />
                  <p>{EXPORTS[1].label}</p>
                </li>
              </ul>
            )}
          </div>
        </li>

        {data.share_permission != PERMISSION_VIEW && (
          <li onClick={() => setOpenDialog(true)} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[2].icon} />
              <p>削除</p>
            </Box>
          </li>
        )}
      </ul>
      <Sharing
        show={showSharingDialog}
        handleClose={handleCloseSharingDialog}
        id={data.piece_id}
        title={data.title}
      />
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このベンチマーク調査を削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </Box>
  );
};

function PiecefolioDetail() {
  const controller = new AbortController()
  const signal = controller.signal

  const { piece_id } = useParams()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()

  const { piece_setting_id } = useSelector(pieceSelector)

  const [selectedRows, setSelectedRows] = useState([])
  const [exportTitle, setExportTitle] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [selectedPieceId, setSelectedPieceId] = useState(null);
  
  const pieceRefs = useRef({});
  const wrapperRef = useRef(null);

  const dispatch = useDispatch()
  const {
    piece,
    isError,
    errors,
    isSuccessfully,
    successMessage,
    isDeleted,
    isProcessing,
  } = useSelector(pieceSelector)

  const onRefreshPiece = async () => {
    setSelectedRows([])

    try {
      await dispatch(regeneratePieceLinks({ piece_id })).unwrap()
      dispatch(getPieceWithLinks({ piece_id, signal }))
    } catch (error) {
      toast.error('再生成に失敗しました。')
    }
  }

  const onBack = () => {
    if (pathname.includes('shared')) {
      navigate('/shared')
    } else {
      navigate(`/piecefolio?piece_setting_id=${piece_setting_id}`)
    }
  }

  const onToggleDrawer = (open, title) => () => {
    setDrawerOpen(open)
    setExportTitle(title)
    if (!open) {
      setSelectedPieceId(null);
    }
  }

  const handleSelectionToggle = (val) => {
    const isSelected = selectedRows.some(
      (row) => row.piece_link_id === val.piece_link_id
    )
    if (isSelected) {
      setSelectedRows(selectedRows.filter(row => row.piece_link_id !== val.piece_link_id))
    } else {
      setSelectedRows([...selectedRows, val])
    }
  }

  const selectedListExportFE = {
    ...piece,
    links: piece?.links?.filter(link =>
      selectedRows.some(selected => selected.piece_link_id === link.piece_link_id)
    ) || []
  };

  const mergeSelectedPiece = (selectedListExportFE, selected_ids) => {
    const linkMap = selectedListExportFE.links.reduce((acc, link) => {
      acc[link.piece_link_id] = link;
      return acc;
    }, {});

    const reorderedLinks = selected_ids.map(item => {
      const existingLink = linkMap[item.id];
      if (existingLink) {
        return {
          ...existingLink,
          title: item.title,
        };
      }
      return null;
    }).filter(link => link !== null);

    return {
      ...selectedListExportFE,
      links: reorderedLinks,
    };
  };

  const onDownload = (payload) => {
    const { selected_ids, file_name } = payload;
    if (selected_ids.length > 0) {
      const selectedListExportFE = {
        ...piece,
        links: piece?.links?.filter(link =>
          selected_ids.some(selected => selected.id === link.piece_link_id)
        ) || []
      };

      const updatedSelectedPiece = mergeSelectedPiece(selectedListExportFE, selected_ids);
      onDownloadBenchmark(updatedSelectedPiece, file_name);
      toast.success(`${file_name} が出力されました！`);
    } else {
      toast.error('質問が選択されていません。');
    }
  };

  const onDownloadFromBE = async (payload) => {

    const { selected_ids, file_name } = payload;
  
    try {
      const response = await templateApi.downloadTemplatePiece({
        selected_piece_links: selected_ids,
        file_name
      });
      
      const url = window.URL.createObjectURL(response);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
  
      link.remove();
      window.URL.revokeObjectURL(url);
      toast.success(`${file_name} が正常にダウンロードされました！`);
    } catch (error) {
      console.error('Error downloading template:', error.response ? error.response.data : error);
      toast.error(error.message || 'テンプレートのダウンロードに失敗しました');
    }
  };

  const handleSelectAll = (selectAll) => {
    if (selectAll) {
      const allRows = piece?.links || [];
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const scrollToPieceId = (piece_id) => {
    const ref = pieceRefs.current[piece_id];
    if (ref && ref.scrollIntoView) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSelectedPieceId(piece_id);
      ref.classList.add(styles.highlight);
      setTimeout(() => {
        ref.classList.remove(styles.highlight);
      }, 2000);
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSelectedPieceId(null);
    }
  };
  
  useEffect(() => {
    dispatch(getPieceWithLinks({ piece_id: piece_id, signal: signal }));
  
    return () => {
      controller.abort();
      dispatch(clearPiece());
    };
  }, [piece_id]);
        

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.message === BENCHMARK_NOT_EXIT) {
          onBack()
        }
        if (er.fieldName !== 'status') toast.error(er.message)
      })
    }
    if (isSuccessfully && successMessage) {
      toast.success(successMessage)
      if (isDeleted) onBack()
    }
    dispatch(clearState())
  }, [isError, isSuccessfully, isDeleted])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (state?.selectAll && state?.openExportPopup && piece?.links?.length > 0) {
      setDrawerOpen(true);
      setExportTitle(state?.exportTitle);
      handleSelectAll(true);
    }
  }, [piece?.links, state]);

  useEffect(() => {
    if (selectedRows.length == 0) {
      setSelectedPieceId(null)
    }
  }, [selectedRows]);

  return (
    <Box className={`${styles.wrapper}`}>
      <Box className={`${styles.wrapperContent} ${drawerOpen ? styles.scaleHorizontal : ''}`}>
        <Button
          onClick={onBack}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          className={`${styles.backButton} border-bottom-theme color-theme`}
        >
          <span>戻る</span>
        </Button>
        {piece?.piece_id && <Actions data={piece} onToggleDrawer={onToggleDrawer} drawerOpen={drawerOpen}/>}
        <Box className={styles.piecefolioContainer}>
          <Box className={`${styles.titleContainer} border-bottom-theme-2`}>
            <ReactSVG src={searchAlt2Icon} />
            <p className={`${styles.titleContent} ${'color-theme'}`}>{piece.title}</p>
          </Box>
          <Box className={styles.dateRangeContainer}>
            <label>期間：</label>
            <label>{`${
              piece.from_date ? moment(piece.from_date).format(FORMAT_DATE_CAPITAL) : 'N/A'
            }~${
              piece.to_date ? moment(piece.to_date).format(FORMAT_DATE_CAPITAL) : 'N/A'
            }`}</label>
          </Box>
          <Box>
            <DataTable
              value={piece.links}
              tableStyle={{ minWidth: '50rem' }}
              rowClassName={(val) => {
                const isSelected = selectedRows.some(
                  (row) => row.piece_link_id === val.piece_link_id
                )
                return `${isSelected ? 'row-selected' : 'row-unselected'} ${styles.row} ${styles.noHover} row-table no-hover space-between ${val.piece_link_id === selectedPieceId ? 'selectedRow' : ''}`
              }}
              tableClassName={styles.piecefolioTable}
              emptyMessage={isProcessing ? " " : NOT_FOUND}
            >
              <Column
                field='publish_date'
                header='記事投稿日'
                className={styles.colDate}
                body={(val) => {
                  return (
                    <p className='mb-0 '>
                      {val?.publish_date ? moment(val?.publish_date).format(FORMAT_DATE_CAPITAL) : ''}
                    </p>
                  )
                }}
              ></Column>
              <Column
                header='記事タイトル'
                className={styles.colTitle}
                body={(val) => (
                  <div className={styles.rowContainer} ref={wrapperRef}>
                    <div
                      className={styles.rowTopBorder}
                      ref={(el) => (pieceRefs.current[val.piece_link_id] = el)}
                    ></div>
                    
                    <Stack className={styles.colTitleContent}>
                      <p
                        className='mb-0'
                      >
                        {val?.title}
                      </p>
                      <a
                        href={val?.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mb-0 truncate color-theme'
                      >
                        {val?.link}
                      </a>
                    </Stack>
                  </div>
                )}
              ></Column>
              <Column
                header={Number(piece?.pattern) === BENCH_MARK_BUSINESS ? '企業名' : 'キーワード'}
                className={styles.colKeywords}
                body={(val) => {
                  return (
                    <Stack className={styles.colKeywords}>
                      {val?.search_keywords.map((word, index) => {
                        return (
                          <span key={`w-${index}`} className={`${styles.keyword}`}>
                            {word}
                          </span>
                        )
                      })}
                    </Stack>
                  )
                }}
              ></Column>
              <Column 
                field='summary' 
                header='要約' 
                className={styles.colSummary} 
                body={(val) => {
                  return (
                    <p className='mb-0 break-words' style={{wordBreak: "break-word" }}>
                      {val.summary}
                    </p>
                  )
                }}></Column>
              <Column
                field='selected'
                header=''
                className={`colSelected bg-color-theme`}
                body={(val) => {
                  const isSelected = selectedRows.some(
                    (row) => row.piece_link_id === val.piece_link_id
                  )
                  return (
                    <Button
                      variant={'contained'}
                      className={`${isSelected ? styles.selected : ''} `}
                      onClick={() => handleSelectionToggle(val)}
                    >
                      {isSelected ? '選択済み' : '選択する'}
                    </Button>
                  )
                }}
              ></Column>
            </DataTable>
            {
              piece.share_permission !== PERMISSION_VIEW && (
                <Box className={styles.bottomButtons}>
                  <Button
                    className={`${styles.btnAddPiece} background-theme background-theme-hover ${(isProcessing || piece.share_permission==PERMISSION_VIEW) ? `edit-disable` : ``}`}
                    variant='contained'
                    onClick={() => navigate('edit')}
                    disabled={isProcessing || piece.share_permission==PERMISSION_VIEW}
                  >
                    再調査
                  </Button>
                  <Button
                    className={`${styles.btnAddPiece} border-bottom-theme color-theme`}
                    variant='outlined'
                    startIcon={<CachedIcon />}
                    disabled={isProcessing || piece.share_permission==PERMISSION_VIEW}
                    onClick={onRefreshPiece}
                  >
                    再生成します
                  </Button>
                </Box>
              )
            }
          </Box>
        </Box>
        {isProcessing && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
      </Box>
      <ExportPopup
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedPieceId(null);
        }}
        exportTitle={exportTitle}
        selectedList={selectedRows}
        onDownload={onDownload}
        onDownloadFromBE={onDownloadFromBE}
        fileNameDefault={piece.title}
        type={EXPORTS_TYPE[2].type}
        onSelectAll={handleSelectAll}
        onTitleClick={scrollToPieceId} 
      />
    </Box>
  )
}

export default PiecefolioDetail
