import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { Link } from 'react-router-dom'
import { HEADER_MENUS, BLUE_THEME } from '@/utils/constant'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import styles from './navbar.module.css'
import { logo, whiteLogo, finchLogo } from '@/utils/images'
import { getUserToken, getUser } from '@/utils/localData'
import { INVITED } from '@/utils/constant'

function index({ whiteTheme = false }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [userName, setUserName] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  React.useEffect(() => {
    const userToken = getUserToken();
    const user = getUser();
    if (userToken && user?.status !== INVITED) {
      setUserName(`${user.firstName} ${user.lastName}`);
    }
  }, []);

  return (
    <AppBar
      className={`appBarTopMenu ${styles.headTopMenu} ${styles.topheadNabar} ${styles.transition1s}  ${
        styles.allPage
      } ${whiteTheme ? styles.whiteTheme : ''} appBarTop w-100`}
      sx={{ backgroundColor: '#000' }}
    >
      <Box className={`${styles.containerHead} w-100 container `}>
        <Toolbar disableGutters className={`${styles.nav} ${styles.menuWrapper} wrapperHMenu`}>
          <Box className={styles.navMobile} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Button className={`${styles.hamburger} button__hamburger`} >
              <span></span>
              <span></span>
              <span></span>
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to='/' className={`${styles.branch} scrollEC`}>
              <img src={whiteTheme ? whiteLogo : logo} />
            </Link>
          </Box>
          
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Link to='/' className={`${styles.branch} ${styles.logoHead} logo-top`}>
              <img src={logo} />
            </Link>
          </Box>
          <Box className={`${styles.menuHead} menuHead`}>
            <Box
              component={'ul'}
              className={styles.navLeft}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {HEADER_MENUS.map((menu) => {
                return (
                  <Box key={`menu-${menu.id}`} component={'li'}>
                    <Link
                      to={menu.link}
                      target={menu.newTab ? '_blank' : '_self'}
                      className={menu.classes}
                    >
                      {menu.title}
                    </Link>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box className={styles.menuRight}>
            <Box component={'ul'}>
              <Box component={'li'}>
                {userName ? (
                  <Link to='/aisearch' className={`${styles.loginText}`}>
                    Hello {userName}
                  </Link>
                ) : (
                  <Link to='/login' className={`${styles.loginText}`}>
                    Sign In
                  </Link>
                )
                }
              </Box>
              <Box component={'li'} className={`scroll--contact`}>
                <Link
                  to='https://company.finchjapan.co.jp/contact.php'
                  target='_bank'
                  className={`${styles.loginText} ${styles.btnContact}`}
                >
                  お問い合わせ
                </Link>
              </Box>
            </Box>
          </Box>
          
        </Toolbar>
      </Box>
      <Box className={`wrapperMenuSP`}>
        <Box className={`mmenuBody`}>
          <ul className={`mmMenuUL`}>
            <li>
              <Link to='/' className={`scroll--what`}>What is InFolio?</Link>
            </li>
            <li>
              <Link to='/' className={`scroll--feature`}>Features</Link>
            </li>
            <li>
              <Link to='/' className={`scroll--value-detail`}>Values</Link>
            </li>
            <li>
              <Link to='/' className={`scroll--news-detail`}>News</Link>
            </li>
            <li>
              <Link to='https://company.finchjapan.co.jp/contact.php'
                  target='_bank'>Contact us</Link>
            </li>
            <li>
              <Link to='https://trail-coneflower-3b0.notion.site/InFolio-FAQ-80da477d21d44ff687be27025faa7a8e'
                  target='_bank'>FAQ</Link>
            </li>
          </ul>
          <ul className={`mmMenuBottom`}>
            <li>
              {userName ? (
                  <Link to='/aisearch' className={`${styles.loginText}`}>
                    Hello {userName}
                  </Link>
                ) : (
                  <Link to='/login' className={`${styles.loginText}`}>
                    Sign In
                  </Link>
                )
                }
            </li>
            <li>
              <Link to='https://company.finchjapan.co.jp/contact.php'
                  target='_bank'>お問い合わせ</Link>
            </li>
          </ul>
          <Box className={`mmMenu__Footer`}>
            <Box className={`mmMenu__Logo text-center`}>
              <Link to='/' className={`mmLogo`}>
                <img src={whiteLogo} />
              </Link>
            </Box>
            <ul className={`mmMenuFooterUL`}>
              <li className='scroll--what'>
                <Link  to='/'>What’s InFolio?</Link>
              </li>
              <li className='scroll--news-detail'>
                <Link  to='/'>News</Link>
              </li>
              <li>
                <Link
                  target='_blank'
                  to='https://trail-coneflower-3b0.notion.site/InFolio-FAQ-80da477d21d44ff687be27025faa7a8e'
                >FAQ</Link>
              </li>
              <li>
                <Link to='https://company.finchjapan.co.jp/contact.php'
                    target='_bank'>Contact Us</Link>
              </li>
              <li>
                <Link to='https://company.finchjapan.co.jp/'
                    target='_bank'>運営会社（フィンチジャパン）</Link>
              </li>
              <li className={`liBorderTopBottom`}>
                <Link  to='/login'>利用者ページ（サインイン）</Link>
              </li>
              <li>
                <Link  to='/law'>特定商取引に関する法律</Link>
              </li>
              <li>
                <Link  to='/privacy-policy'>プライバシーポリシー</Link>
              </li>
              <li>
                <Link  to='/terms-of-service'>サービス利用規約</Link>
              </li>
            </ul>
            <Box className={`text-center`}>
              <img className={`mmLogoFinch`} src={finchLogo} />
              <p className={`mmCopyright`}>©Finchjapan, 2024. All rights reserved.</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppBar>
  )
}

export default React.memo(index)
