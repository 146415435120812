import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import pieceApi from '@/api/piecefolio'
import { catchError } from '@/utils/errorHandling'
import { PERMISSION_OWNER, PERMISSION_DELETE } from '@/utils/constant'


export const getPieceSetting = createAsyncThunk('piece/getSetting', async (data, thunkAPI) => {
  try {
    let res = await pieceApi.getPieceSetting(data)
    if (!res?.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const pieceSetting = createAsyncThunk('piece/setting', async (data, thunkAPI) => {
  try {
    let res = await pieceApi.pieceSetting(data)
    if (!res?.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const createPiece = createAsyncThunk('piece/create', async (data, thunkAPI) => {
  try {
    const res = await pieceApi.createPiece(data)
    if (!res?.errors) {
      return { ...res, ...data, links: [], share_permission: PERMISSION_OWNER }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const editPiece = createAsyncThunk('piece/edit', async (data, thunkAPI) => {
  try {
    const res = await pieceApi.createPiece(data)
    if (!res?.errors) {
      return { ...res, ...data, links: [] }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getAllPiece = createAsyncThunk('piece/gets', async (data, thunkAPI) => {
  try {
    let res = await pieceApi.getAllPiece(data)
    if (!res?.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getPiece = createAsyncThunk('piece/get', async (data, thunkAPI) => {
  try {
    let res = await pieceApi.getPiece(data)
    if (!res.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getPieceWithLinks = createAsyncThunk(
  'piece/getPieceWithLinks',
  async ({ piece_id, signal }, thunkAPI) => {
    try {
      const res = await pieceApi.getPieceLinks({ piece_id, signal })
      if (!res?.errors) {
        return res
      }
    } catch (e) {
      return catchError(e, thunkAPI, signal)
    }
  }
)

export const deletePiece = createAsyncThunk('piece/delete', async (data, thunkAPI) => {
  try {
    let res = await pieceApi.deletePiece({ pieceId: data.piece_id })
    if (!res.errors) {
      return { ...res, piece_id: data.piece_id, key: data.key }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getMembersToShare = createAsyncThunk(
  'piece/gets-share',
  async (data, thunkAPI) => {
    try {
      const res = await pieceApi.getMembersToShare(data)
      if (!res.errors) {
        return res
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors })
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    }
  }
)

export const getSharedMembers = createAsyncThunk(
  'piece/gets-shared',
  async (data, thunkAPI) => {
    try {
      const res = await pieceApi.getMembersShared(data)
      if (!res.errors) {
        return { topicId: data.topicId, ...res }
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors })
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    }
  }
)

export const sharePiece = createAsyncThunk('piece/share', async (data, thunkAPI) => {
  try {
    const payload = {
      piece_id: data.piece_id,
      shared_user_id: data.member.user_id,
      share_permission: data.share_permission,
    }
    const res = await pieceApi.sharePiece(payload)
    if (!res.errors) {
      return { member: { ...data.member, share_permission: data.share_permission }, ...res }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const setPages = createAsyncThunk('piece/change-page', async (data) => {
  return data
})

export const refreshPiece = createAsyncThunk('piece/refresh', async (data, thunkAPI) => {
  try {
    const res = await pieceApi.refreshPiece(data)
    if (!res?.errors) {
      return { ...res, ...data, links: [] }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const resumePiece = createAsyncThunk('piece/resume', async (data, thunkAPI) => {
  try {
    const res = await pieceApi.resumePiece(data)
    if (!res?.errors) {
      return { ...res }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const regeneratePieceLinks = createAsyncThunk('piece/regenerateLinks', async (data, thunkAPI) => {
    try {
      const response = await pieceApi.regeneratePiece(data);
      return response;
    } catch (e) {
      return catchError(e, thunkAPI, data.signal);
    }
  }
);

export const getSharedPieces = createAsyncThunk(
  'piece/get-list-shared-pieces',
  async (data, thunkAPI) => {
    try {
      const res = await pieceApi.getSharedPieces(data);
      if (!res.errors) {
        return res;
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors });
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e });
    }
  }
);

export const pieceSlice = createSlice({
  name: 'piece',
  initialState: {
    piece_setting: [],
    piece_setting_id: null,
    // sites: [],
    // keywords: [],
    list: {}, // {owner: [], shared: []}
    piece: {
      finished: true,
      links: [],
    },
    pieceToCopy: null,
    membersToShare: [],
    sharedMembers: [],
    isSuccessfully: false,
    successMessage: '',
    isError: false,
    errors: {},
    isFetching: false,
    isFetchingMembers: false,
    isFetchingSharedMembers: false,
    saveSuccess: false,
    isProcessing: false,
    isDeleted: false,
    gettingCopy: false,
    isFetchingWithLink: false,
    pages: [],
    errorStop: false,
    isStop: false,
    isSharing: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccessfully = false
      state.errors = {}
      state.successMessage = ''
      state.isFetching = false
      state.saveSuccess = false
      state.isProcessing = false
      state.isFetchingMembers = false
      state.isFetchingSharedMembers = false
      state.isDeleted = false
      state.gettingCopy = false
      state.errorStop = false
      state.isStop = false
      return state
    },
    clearPiece: (state) => {
      state.piece = { finished: true, links: [] }
    },
    clearPieceCopy: (state) => {
      state.pieceToCopy = null
    },
    savePieceSettingId: (state, action) => {
      state.piece_setting_id = action.payload;
    },
  },
  extraReducers: {
    // getPieceSetting
    [getPieceSetting.fulfilled]: (state, { payload }) => {
      // state.sites = payload.sites || []
      // state.keywords = payload.keywords || []
      // state.piece_setting_id = payload.piece_setting_id || null
      state.piece_setting = payload 
      state.isFetching = false
    },
    [getPieceSetting.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getPieceSetting.pending]: (state) => {
      state.isFetching = true
    },
    // getAllPiece
    [getAllPiece.fulfilled]: (state, { payload }) => {
      state.list = payload
      state.isFetching = false
      return state
    },
    [getAllPiece.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getAllPiece.pending]: (state) => {
      state.isFetching = true
    },
    [getPiece.fulfilled]: (state, { payload }) => {
      state.pieceToCopy = { ...payload }
      state.gettingCopy = false
      state.isSuccessfully = true
      return state
    },
    [getPiece.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.gettingCopy = false
    },
    [getPiece.pending]: (state) => {
      state.gettingCopy = true
    },
    [createPiece.fulfilled]: (state, { payload }) => {
      state.piece = { ...payload }
      state.isProcessing = false
      state.isSuccessfully = true
      return state
    },
    [createPiece.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isProcessing = false
    },
    [createPiece.pending]: (state) => {
      state.isProcessing = true
    },
    [editPiece.fulfilled]: (state, { payload }) => {
      state.piece = { ...payload }
      state.isProcessing = false
      state.isSuccessfully = true
      return state
    },
    [editPiece.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isProcessing = false
    },
    [editPiece.pending]: (state) => {
      state.isProcessing = true
    },
    [getMembersToShare.fulfilled]: (state, { payload }) => {
      const { user_to_share } = payload
      state.membersToShare = [...user_to_share]
      state.isFetchingMembers = false
      return state
    },
    [getMembersToShare.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetchingMembers = false
    },
    [getMembersToShare.pending]: (state) => {
      state.isFetchingMembers = true
    },
    [getSharedMembers.fulfilled]: (state, { payload }) => {
      const { user_shared } = payload
      state.sharedMembers = [...user_shared]
      state.isFetchingSharedMembers = false
      return state
    },
    [getSharedMembers.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetchingSharedMembers = false
    },
    [getSharedMembers.pending]: (state) => {
      state.isFetchingSharedMembers = true
    },
    [sharePiece.fulfilled]: (state, { payload }) => {
      const { message, member } = payload
      let { sharedMembers } = state

      const index = sharedMembers.findIndex((mem) => mem.user_id === member.user_id)

      if (index > -1) {
        if (member.share_permission === PERMISSION_DELETE) sharedMembers.splice(index, 1)
        else sharedMembers[index] = { ...member }
      } else sharedMembers = [member, ...sharedMembers]

      state.sharedMembers = sharedMembers
      state.isSuccessfully = true
      state.isSharing = false
      state.successMessage = message
      return state
    },
    [sharePiece.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isSharing = false
    },
    [sharePiece.pending]: (state) => {
      state.isSharing = true
    },
    // getPieceWithLinks
    [getPieceWithLinks.fulfilled]: (state, { payload }) => {
      state.piece = {
        ...state.piece,
        ...payload,
      }
      state.isFetchingWithLink = false
      return state
    },
    [getPieceWithLinks.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }

      state.isFetchingWithLink = false
    },
    [getPieceWithLinks.pending]: (state) => {
      state.isFetchingWithLink = true
    },
    // delete piece
    [deletePiece.fulfilled]: (state, { payload }) => {
      if (payload.key) {
        const { key, piece_id } = payload
        let list = state.list[key].filter((item) => item.piece_id !== piece_id)
        state.list[key] = list
      }
      state.isProcessing = false
      state.isSuccessfully = true
      state.successMessage = payload.messages
      state.isDeleted = true
      return state
    },
    [deletePiece.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [deletePiece.pending]: (state) => {
      state.isProcessing = true
    },
    [setPages.fulfilled]: (state, { payload }) => {
      const index = state.pages.findIndex((page) => page.title === payload.title)
      if (index > -1) {
        state.pages[index] = payload
      } else {
        state.pages.push(payload)
      }
      return state
    },
    [refreshPiece.fulfilled]: (state, {}) => {
      state.piece = {
        ...state.piece,
        finished: false,
        links: [],
      }
      state.isProcessing = false
      state.isSuccessfully = true
      return state
    },
    [refreshPiece.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isProcessing = false
    },
    [refreshPiece.pending]: (state) => {
      state.isProcessing = true
    },
    [resumePiece.fulfilled]: (state, {}) => {
      state.isStop = false
      return state
    },
    [resumePiece.rejected]: (state, { payload }) => {
      state.isStop = true
    },
    [resumePiece.pending]: (state) => {
      state.isStop = false
    },
    [regeneratePieceLinks.fulfilled]: (state, { payload }) => {
      state.piece.links = payload.links;
      state.isProcessing = false;
      state.isSuccessfully = true;
    },
    [regeneratePieceLinks.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errors = payload.errors;
      state.isProcessing = false;
    },
    [regeneratePieceLinks.pending]: (state) => {
      state.isProcessing = true;
    },
    [getSharedPieces.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.isFetching = false;
      return state;
    },
    [getSharedPieces.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true;
        state.errors = payload.errors;
      }
      state.isFetching = false;
    },
    [getSharedPieces.pending]: (state) => {
      state.isFetching = true;
    },
  },
})

export const { clearState, clearPiece, clearPieceCopy, savePieceSettingId } = pieceSlice.actions

export const selectPieceSettingId = (state) => state.piecefolio.piece_setting_id
export const selectSites = (state) => state.piecefolio.sites
export const selectKeywords = (state) => state.piecefolio.keywords

export const pieceSelector = (state) => state.piecefolio
