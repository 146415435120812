import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import shareApi from '@/api/share'
import pieceApi from '@/api/piecefolio'
import { PERMISSION_DELETE } from '@/utils/constant'

export const getMembersToShare = createAsyncThunk('share/members', async (data, thunkAPI) => {
  try {
    const res = await shareApi.getMembersToShare(data)
    if (!res.errors) {
      return res
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const getSharedMembers = createAsyncThunk('share/shared-members', async (data, thunkAPI) => {
  try {
    const res = await shareApi.getMembersShared(data)
    if (!res.errors) {
      return { topicId: data.topicId, ...res }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const shareTopic = createAsyncThunk('share/create', async (data, thunkAPI) => {
  try {
    const payload = {
      topic_id: data.topic_id,
      shared_user_id: data.member.userId,
      share_permission: data.share_permission,
    }
    const res = await shareApi.shareTopic(payload)
    if (!res.errors) {
      return { member: { ...data.member, sharePermission: data.share_permission }, ...res }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const getSharedTopic = createAsyncThunk('share/get-list-shared', async (_, thunkAPI) => {
  try {
    const res = await shareApi.getSharedTopic()
    if (!res.errors) {
      return res
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const markReadAll = createAsyncThunk('share/mark-read-all', async (_, thunkAPI) => {
  try {
    const res = await shareApi.markReadAll()
    if (!res.errors) {
      return res
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const getUnreadTopic = createAsyncThunk('share/get-unread-topic', async (_, thunkAPI) => {
  try {
    const res = await shareApi.getUnreadTopic()
    if (!res.errors) {
      return res
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const shareBenchmark = createAsyncThunk('share/benchmark', async (data, thunkAPI) => {
  try {
    const payload = {
      benchmark_id: data.benchmark_id,
      shared_user_id: data.member.userId,
      share_permission: data.share_permission,
    }
    const res = await shareApi.shareBenchmark(payload)
    if (!res.errors) {
      return { member: { ...data.member, sharePermission: data.share_permission }, ...res }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const setPages = createAsyncThunk('share/chage-page', async (data) => {
  return data
})

export const sharePiece = createAsyncThunk('share/piecefolio', async (data, thunkAPI) => {
  try {
    const payload = {
      piece_id: data.piece_id,
      shared_user_id: data.member.userId,
      share_permission: data.share_permission,
    }
    const res = await pieceApi.sharePiece(payload)
    if (!res.errors) {
      return { member: { ...data.member, sharePermission: data.share_permission }, ...res }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const shareSlice = createSlice({
  name: 'share',
  initialState: {
    listShared: [],
    members: [],
    sharedMembers: [],
    isSuccessfully: false,
    successMessage: '',
    isError: false,
    errors: {},
    isFetching: false,
    isFetchingMembers: false,
    isFetchingSharedMembers: false,
    isProcessing: false,
    unreadCountTopic: 0,
    pages: [],
  },
  reducers: {
    clearState: (state) => {
      state.isSuccessfully = false
      state.successMessage = ''
      state.isError = false
      state.errors = {}
      state.isFetching = false
      state.isProcessing = false
      return state
    },
  },
  extraReducers: {
    [shareTopic.fulfilled]: (state, { payload }) => {
      const { message, member } = payload
      let { sharedMembers } = state

      const index = sharedMembers.findIndex((mem) => mem.userId === member.userId)

      if (index > -1) {
        if (member.sharePermission === PERMISSION_DELETE) sharedMembers.splice(index, 1)
        else sharedMembers[index] = { ...member }
      } else sharedMembers = [member, ...sharedMembers]

      state.sharedMembers = sharedMembers
      state.isSuccessfully = true
      state.isProcessing = false
      state.successMessage = message
      return state
    },
    [shareTopic.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [shareTopic.pending]: (state) => {
      state.isProcessing = true
    },
    [getMembersToShare.fulfilled]: (state, { payload }) => {
      const { userToShare } = payload

      state.members = [...userToShare]
      state.isFetchingMembers = false
      return state
    },
    [getMembersToShare.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetchingMembers = false
    },
    [getMembersToShare.pending]: (state) => {
      state.isFetchingMembers = true
    },
    [getSharedMembers.fulfilled]: (state, { payload }) => {
      const { userShared, topicId } = payload
      userShared.forEach((user) => {
        user.topicId = topicId
      })
      state.sharedMembers = [...userShared]
      state.isFetchingSharedMembers = false
      return state
    },
    [getSharedMembers.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetchingSharedMembers = false
    },
    [getSharedMembers.pending]: (state) => {
      state.isFetchingSharedMembers = true
    },
    [getSharedTopic.fulfilled]: (state, { payload }) => {
      state.listShared = payload.shared
      state.isFetching = false
      return state
    },
    [getSharedTopic.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetching = false
    },
    [getSharedTopic.pending]: (state) => {
      state.isFetching = true
    },
    [getUnreadTopic.fulfilled]: (state, { payload }) => {
      state.unreadCountTopic = payload.unreadTopicNumber
      return state
    },
    [getUnreadTopic.rejected]: (state, {}) => {
      return state
    },
    [getUnreadTopic.pending]: (state) => {
      return state
    },
    [markReadAll.fulfilled]: (state, {}) => {
      state.unreadCountTopic = 0
      return state
    },
    [markReadAll.rejected]: (state, {}) => {
      return state
    },
    [markReadAll.pending]: (state) => {
      return state
    },
    [setPages.fulfilled]: (state, { payload }) => {
      const index = state.pages.findIndex((page) => page.title === payload.title)
      if (index > -1) {
        state.pages[index] = payload
      } else {
        state.pages.push(payload)
      }
      return state
    },
  },
})

export const { clearState } = shareSlice.actions

export const shareSelector = (state) => state.share
