import React, { forwardRef , useEffect } from 'react'; 
import Box from '@mui/material/Box';
import Sources from './Sources';
import Answer from './Answer';
import styles from './search.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { chatSelector, getAnswer } from '@/redux/modules/chat';
import { searchAlt2Icon, userSquareIcon } from '@/utils/images';
import { ReactSVG } from 'react-svg';
import { Button } from '@mui/material';

const Question = forwardRef(({ data, detectHeight, toggleSelectedQuestion, selectedQuestions, selectedQuestionId, wrapperRef }, ref) => {
  const dispatch = useDispatch();
  const { isFetchingSources, isFetchingAnswer, currentQuestion } = useSelector(chatSelector);
  const isSelected = selectedQuestions.some((q) => q.question_id === data?.question_id);
  const isSelectedId = selectedQuestionId=== data?.question_id;

  useEffect(() => {
    if (data && data?.question_id === currentQuestion?.question_id) {
      if (!currentQuestion.finished) {
        dispatch(getAnswer({ question_id: data.question_id, idx: currentQuestion.index }));
      } else if (!currentQuestion?.answer && !data.answer) {
        dispatch(getAnswer({ question_id: data.question_id, idx: currentQuestion.index }));
      }
    }
  }, [currentQuestion]);

  if (!data) return null;
  return (
    <Box ref={ref} className={`${styles.boxQuestion}`}>
      <Box className={`${styles.header} search-icon border-bottom-theme-2`}>
        <ReactSVG src={searchAlt2Icon} />
        <p className={`${styles.content} color-theme`}>{data.question}</p>
      </Box>
      <Box className={`${styles.boxResponsed} ${isSelected ? styles.questionSelected : ""} ${isSelectedId ? styles.selectedId : ''}`} ref={wrapperRef}>
        <Box className={`${styles.boxCreatedAndSelect} search-icon`}>
          <div className={`${styles.boxCreatedBy} search-icon`}>
            <ReactSVG src={userSquareIcon} />
            <p className={`${styles.content}`}>
              {data?.created_last_name || ''}
              {data?.created_first_name || ''}
            </p>
          </div>
          <Button
            variant={'contained'}
            onClick={() => toggleSelectedQuestion(data.question_id)}
            className={`${isSelected ? styles.selected : ''}`}
          >
            {isSelected ? '選択済み' : '選択する'}
          </Button>
        </Box>
        <Sources
          loading={isFetchingSources && !data.links}
          data={data.links}
          animation={currentQuestion?.question_id === data?.question_id}
          isSelected={isSelected}
        />
        <Answer
          loading={isFetchingSources && !data.links}
          loadingMini={isFetchingAnswer && currentQuestion?.question_id === data?.question_id}
          data={data}
          detectHeight={detectHeight}
        />
      </Box>
    </Box>
  );
});

export default Question;
