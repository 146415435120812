import React, { useEffect, useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Box from '@mui/material/Box'
import styles from './piecefolio.module.css'
import {
  ACTIONS,
  EXPORTS,
  DEFAULT_ITEMS_PER_PAGE_BENCHMARK,
  PERMISSION_VIEW,
  FORMAT_DATE_CAPITAL,
  SELF,
} from '@/utils/constant'
import { useNavigate } from 'react-router-dom'
import { ConfirmDialog, ReadMore } from '@/components'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { trashIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { pieceSelector, deletePiece, setPages, clearState } from '@/redux/modules/piecefolio'
import useTheme from '@/hooks/useTheme'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search';
import toast from 'react-hot-toast'

function PiecefolioTable({ data, title }) {

  const tableRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isProcessing, isDeleted, pages } = useSelector(pieceSelector)

  const [openDialog, setOpenDialog] = useState(false)
  const [piecefolioSelected, setPiecefolioSelected] = useState()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 'auto', bottom: 'auto' })

  const [first1, setFirst1] = useState(0)
  const [rows1, setRows1] = useState(DEFAULT_ITEMS_PER_PAGE_BENCHMARK)

  const sortMeta = [
    { field: 'piece_setting_id', order: 1 },
    { field: 'piece_id', order: -1 }         
  ]

  const [filterText, setFilterText] = useState('')
  const filteredItems = data
    .filter((item) => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()))
    .reverse()

    const handleDelete = () => {
      dispatch(deletePiece({ piece_id: piecefolioSelected.piece_id, key: title }))
    };
    

  const openDialogConfirm = (val) => {
    setOpenDialog(true)
    setPiecefolioSelected(val)
  }

  const handlePageChange = (event) => {
    const { page, rows } = event
    dispatch(setPages({ title: title, currentPage: page + 1, rows: rows }))
  }

  const toggleDropdown = (e, topicId) => {
    e.stopPropagation()

    const elementRect = e.currentTarget.getBoundingClientRect();
    const dropdownHeight = 104;

    const spaceBelow = window.innerHeight - elementRect.bottom;
    const shouldOpenAbove = spaceBelow < dropdownHeight;

    setDropdownPosition({
      top: shouldOpenAbove ? 'auto' : `${elementRect.height}px`,
      bottom: shouldOpenAbove ? `${elementRect.height}px` : 'auto',
    });

    setDropdownOpen(dropdownOpen === topicId ? null : topicId)
  }

  const handleItemClick = (topicId, exportTitle) => {
    navigate(`/piecefolio/${topicId}`, {
      state: {
        selectAll: true,
        openExportPopup: true,
        exportTitle: exportTitle,
      },
    });
  };

  useEffect(() => {
    if (isDeleted) setOpenDialog(false)
  }, [isDeleted])

  useEffect(() => {
    if (pages.length) {
      pages.map((page) => {
        if (page.title === title) {
          const first = page.currentPage ? page.rows * (page.currentPage - 1) : 0
          setFirst1(first)
          setRows1(page.rows)
        }
      })
    }
  }, [pages])

  if (!data.length) return null

  return (
    <div style={{ paddingBottom: '50px' }}>
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
        <div >
          <img src={title === SELF ? useTheme().user1Icon : useTheme().users} />
          <label className={`color-theme ${styles.title}`}>
            {title === SELF ? '自分の調査' : '法人内の調査'}
          </label>
        </div>
      <Box className={styles.boxSearch}>
          <Input
            placeholder='履歴内の検索'
            onChange={(e) => setFilterText(e.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{
              '&.MuiInputBase-root': {
                '&.MuiInput-root:before': { borderBottom: '1px solid #414141' },
              },
            }}
          />
        </Box>
      </Box>
      <div className={styles.group}>
        <DataTable
          className={`${styles.tableWrapper}`}
          ref={tableRef}
          value={filterText ? filteredItems : data}
          paginator={data.length > DEFAULT_ITEMS_PER_PAGE_BENCHMARK}
          rowClassName={`${styles.row} row-table space-between background-theme-hover`}
          tableClassName={styles.piecefolioTable}
          rowsPerPageOptions={[5, 10, 20, 30]}
          onPage={handlePageChange}
          first={first1}
          rows={rows1}
          sortField='created_time'
          onRowClick={(e) => navigate(`${e.data.piece_id}`)}
          sortMode={'multiple'}
          sortMeta={sortMeta}
          multiSortMeta={sortMeta}
        >
          <Column
            header='調査日'
            sortable
            style={{ width: 150 }}
            field='created_time'
            body={(val) => {
              return (
                <span>
                  {val.created_time ? moment(val.created_time).format(FORMAT_DATE_CAPITAL) : ''}
                </span>
              )
            }}
          ></Column>
          <Column
            field='title'
            sortable
            header='タイトル'
            body={({ title }) => {
              return <ReadMore>{title}</ReadMore>
            }}
          ></Column>
          <Column
            header='期間'
            body={(val) => {
              return (
                <span>
                  {val.from_date ? moment(val.from_date).format(FORMAT_DATE_CAPITAL) : ''}~
                  {val.from_date ? moment(val.to_date).format(FORMAT_DATE_CAPITAL) : ''}
                </span>
              )
            }}
            className={styles.colDates}
          ></Column>
          <Column
            header=''
            field='actions'
            className={styles.colActions}
            body={(val) => {
              return (
                <Box className={styles.boxActions}>
                  <ul className={`bg-theme-hover-opacity`}>
                    <li 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`add-new?copy=${val.piece_id}`)
                      }} 
                      className={styles.action}
                    >
                      <Box className={`${styles.boxAction}`}>
                        <ContentCopyIcon />
                        <p>コピー</p>
                      </Box>
                    </li>
                    <li
                      className={styles.action}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(e, val.piece_id)
                      }}
                      // onClick={(e) => handleItemClick(val.piece_id);
                    >
                      <Box className={styles.boxAction}>
                        <img src={ACTIONS[1].icon} />
                        <p>出力</p>
                      </Box>
                      {dropdownOpen === val.piece_id && (
                        <ul className={styles.dropdownMenu} style={{ top: dropdownPosition.top, bottom: dropdownPosition.bottom }}>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleItemClick(val.piece_id, EXPORTS[0].label);
                            }}
                          >
                              <img src={EXPORTS[0].icon} />
                              <p>{EXPORTS[0].label}</p>
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleItemClick(val.piece_id, EXPORTS[1].label);
                            }}
                          >
                              <img src={EXPORTS[1].icon} />
                              <p>{EXPORTS[1].label}</p>
                          </li>
                        </ul>
                      )}
                    </li>
                    {title === SELF || val.share_permission != PERMISSION_VIEW ? (
                      <li 
                        onClick={(e) => {
                          e.stopPropagation();
                          openDialogConfirm(val)
                        }}
                      className={styles.action}>
                        <Box className={`${styles.boxAction}`}>
                          <ReactSVG src={trashIcon} />
                          <p>削除</p>
                        </Box>
                      </li>
                    ) : null}
                  </ul>
                </Box>
              )
            }}
          ></Column>
        </DataTable>
      </div>

      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このベンチマーク調査を削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </div>
  )
}

export default PiecefolioTable
