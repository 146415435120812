import React, { useState, useRef, useEffect } from 'react'
import {
  FormLayout,
  DatePickerWithMonthYearDropdown,
  CustomInput,
  InputWithLabel,
  Label,
} from '@/components'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import styles from './piecefolio.module.css'
import InputAdornment from '@mui/material/InputAdornment'
import { BENCH_MARK_BUSINESS } from '@/utils/constant'
import { plusLargeIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'
import { useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  getPiece,
  createPiece,
  clearState,
  pieceSelector,
  clearPieceCopy,
  editPiece,
} from '@/redux/modules/piecefolio'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment/moment'
import toast from 'react-hot-toast'

function AddEdit() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const piecefolioCopy = searchParams.get('copy')
  const { piece_id } = useParams()

  const dispatch = useDispatch()
  const { isSuccessfully, isProcessing, piece, errors, pieceToCopy, gettingCopy, isError, piece_setting, piece_setting_id } =
    useSelector(pieceSelector)
    

  const [error, setError] = useState(null)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [keyword, setKeyword] = useState('')
  const [keywords, setKeywords] = useState([])
  const [title, setTitle] = useState('')

  const titleRef = useRef(null)
  const dateRangeRef = useRef(null)
  const keywordRef = useRef(null)

  const filteredPieceSetting = piece_setting.filter(item => item.piece_setting_id === piece_setting_id);
  const updatedTime = filteredPieceSetting.length > 0 ? new Date(filteredPieceSetting[0].updated_time) : new Date();

  const onAddNewKeyWord = () => {
    if (!keyword.trim()) {
      keywordRef?.current?.focus()
      setError((prevstate) => ({ ...prevstate, keywords: '必須項目です。' }))
    } else if (keyword.length > 255) {
      setError((prevstate) => ({ ...prevstate, keywords: 'キーワードは255文字以内で入力してください。' }))
    } else {
      setKeywords((prevState) => [keyword, ...prevState])
      setKeyword('')
      setError((prevstate) => ({ ...prevstate, keywords: '' }))
    }
  }
  

  const onSavePiece = () => {
    setError(null)
    let newData = {
      title: title.trim(),
      keywords: keywords,
      from_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      to_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      piece_setting_id: piece_setting_id
    }

    if (piece_id) {
      dispatch(editPiece({ ...newData, piece_id: piece_id }))
    } else {
      dispatch(createPiece(newData))
    }
  }

  const onChangeTitle = (e) => {
    setTitle(e.target.value)
  }

  useEffect(() => {
    const controller = new AbortController()

    if (piecefolioCopy || piece_id) {
      const signal = controller.signal
      const searchId = piecefolioCopy || piece_id
      dispatch(getPiece({ piece_id: searchId, signal: signal }))
    }

    return () => {
      controller.abort()
      dispatch(clearPieceCopy())
    }
  }, [])

  useEffect(() => {
    if (isSuccessfully && piece?.piece_id) {
      dispatch(clearState())
      if (pathname.includes('shared')) {
        navigate(`/shared/piece/${piece.piece_id}`, { state: { from: 'add-new' } })
      } else {
        navigate(`/piecefolio/${piece.piece_id}`, { state: { from: 'add-new' } })
      }
    }
  }, [isSuccessfully, piece])

  useEffect(() => {
    if (isError) {
      if (errors.message) toast.error(errors.message)
      else setError(errors)
      dispatch(clearState())
    }
  }, [errors, isError])

  useEffect(() => {
    if (pieceToCopy) {
      setTitle(pieceToCopy?.title)
      setKeywords(pieceToCopy?.keywords)
      setDateRange([new Date(pieceToCopy.from_date), new Date(pieceToCopy.to_date)])
    }
  }, [pieceToCopy])

  return (
    <FormLayout
      title='PieceFolio'
      onClick={onSavePiece}
      disableButton={isProcessing}
      isProcessing={isProcessing}
      saveLable='調査開始'
      width='85%'
    >
      <Box className={styles.form}>
        {!gettingCopy ? (
          <>
            <InputWithLabel
              label='調査名'
              required
              ref={titleRef}
              placeholder='調査名を入力'
              value={title}
              onChange={onChangeTitle}
              error={error && !!error['title']}
              errorText={(error && error['title']) || ''}
              disabled={isProcessing}
            />
            <InputWithLabel
              label={'キーワード'}
              required
              ref={keywordRef}
              placeholder={'キーワードを追加'}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) onAddNewKeyWord()
              }}
              error={error && !!error['keywords']}
              errorText={(error && error['keywords']) || ''}
              disabled={isProcessing}
              endAdornment={
                <InputAdornment position='end' onClick={onAddNewKeyWord} className={styles.addMore}>
                  <ReactSVG src={plusLargeIcon} className={styles.iconAddMore} />
                </InputAdornment>
              }
            >
              <Box className={styles.keywordList}>
                {keywords.map((val, index) => {
                  return (
                    <Box key={`word-${val}-${index}`} className={styles.item}>
                      <p className='mb-0'>{val}</p>
                      <CloseIcon
                        className={`${styles.icon} ${isProcessing && 'd-none'}`}
                        onClick={() => {
                          setKeywords((prevState) => prevState.filter((_, i) => i !== index))
                        }}
                      />
                    </Box>
                  )
                })}
              </Box>
            </InputWithLabel>
            <Box className={`${styles.rowForm} ${styles.inputGroup}`}>
              <Label label='期間' required />
              <Stack className={styles.inpContainer} style={{ flex: 5 }}>
                <DatePickerWithMonthYearDropdown
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update)
                  }}
                  minDate={moment(updatedTime).subtract(1, 'months').toDate()}
                  maxDate={new Date()}
                  isClearable={true}
                  placeholderText='期間'
                  customInput={
                    <CustomInput
                      ref={dateRangeRef}
                      error={error && (!!error['from_date'] || !!error['to_date'])}
                      errorText={(error && (error['from_date'] || error['to_date'])) || ''}
                    />
                  }
                  disabled={isProcessing}
                />
              </Stack>
            </Box>
          </>
        ) : (
          <>
            {Array(4)
              .fill(null)
              .map((_, i) => {
                return <Skeleton key={`ske-${i}`} variant='rounded' height={65} />
              })}
          </>
        )}
      </Box>
    </FormLayout>
  )
}

export default AddEdit
