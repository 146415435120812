import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import moment from 'moment'
import { userSquareIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'
import {
  ACTIONS,
  EXPORTS,
  EXPORTS_TYPE,
  SELF,
  FORMAT_DATE_CAPITAL,
  PERMISSION_VIEW,
  DEFAULT_ITEMS_PER_PAGE_SHARED,
} from '@/utils/constant'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import { trashIcon } from '@/utils/images'
import { ConfirmDialog, ReadMore } from '@/components'
import styles from './styles.module.css'
import { shareSelector, setPages } from '@/redux/modules/share'
import { useDispatch, useSelector } from 'react-redux'
import { NOT_FOUND } from '@/utils/message'

export default function SharedDataTable({
  data = [],
  showAction = false,
  closeDialog = false,
  isProcessing = false,
  onDelete = () => {},
  title = '',
  type = ''
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pages } = useSelector(shareSelector)

  const [openDialog, setOpenDialog] = useState(false)
  const [dataSelected, setDataSelected] = useState()

  const [first1, setFirst1] = useState(0)
  const [rows1, setRows1] = useState(DEFAULT_ITEMS_PER_PAGE_SHARED)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 'auto', bottom: 'auto' })

  const handleDelete = () => {
    onDelete(type=='benchmark' ? dataSelected.benchmark_id : dataSelected.piece_id, 'shared')
    setDataSelected(null)
    setOpenDialog(false)
  }

  const openDialogConfirm = (val) => {
    setOpenDialog(true)
    setDataSelected(val)
  }

  const handlePageChange = (event) => {
    const { page, rows } = event
    dispatch(setPages({ title: title, currentPage: page + 1, rows: rows }))
  }

  useEffect(() => {
    if (!openDialog && dataSelected) {
      setDataSelected(null)
    }
  }, [openDialog, dataSelected])

  useEffect(() => {
    if (pages.length) {
      pages.map((page) => {
        if (page.title === title) {
          const first = page.currentPage ? page.rows * (page.currentPage - 1) : 0
          setFirst1(first)
          setRows1(page.rows)
        }
      })
    }
  }, [pages])

  const headerTemplate = (value) => {
    const currenDate = moment().startOf('day')
    const daysDiff = currenDate.diff(moment(value.shared_time, [FORMAT_DATE_CAPITAL]), 'days')
    const groupTitle = daysDiff === 0 ? '今日' : daysDiff === 1 ? '昨日' : value.shared_time

    return (
      <div className='flex align-items-center gap-2'>
        <span className='font-bold'>{groupTitle}</span>
      </div>
    )
  }

  const headerPieceTemplate = (value) => {
    return (
      <div className='flex align-items-center gap-2'>
        <span className='font-bold'>{value.piece_setting_title}</span>
      </div>
    )
  }

  const getValueId = (value, type) => {
    switch (type) {
      case EXPORTS_TYPE[0].type:
        return value.topic_id
      case EXPORTS_TYPE[1].type:
        return value.benchmark_id
      case EXPORTS_TYPE[2].type:
        return value.piece_id
      default:
        return value.topic_id
    }
  }

  const toggleDropdown = (e, value) => {
    const dropDownId = getValueId(value, type)

    e.stopPropagation()

    const elementRect = e.currentTarget.getBoundingClientRect();
    const dropdownHeight = 104;

    const spaceBelow = window.innerHeight - elementRect.bottom;
    const shouldOpenAbove = spaceBelow < dropdownHeight;

    setDropdownPosition({
      top: shouldOpenAbove ? 'auto' : `${elementRect.height}px`,
      bottom: shouldOpenAbove ? `${elementRect.height}px` : 'auto',
    });

    setDropdownOpen(dropdownOpen === dropDownId ? null : dropDownId)
  }

  const navAction = (value, type, state) => {
    switch (type) {
      case EXPORTS_TYPE[0].type:
        return navigate(`/shared/lib/${getValueId(value, type)}`, { state });
      case EXPORTS_TYPE[1].type:
        return navigate(`/shared/ben/${getValueId(value, type)}`, { state });
      case EXPORTS_TYPE[2].type:
        return navigate(`/shared/piece/${getValueId(value, type)}`, { state });
      default:
        return navigate(`/shared/lib/${getValueId(value, type)}`, { state });
    }
  }

  const getShareName = (value) => {
    switch (type) {
      case EXPORTS_TYPE[0].type:
        return `${value.sharing_last_name}${value.sharing_first_name}`
      case EXPORTS_TYPE[1].type:
        return `${value.created_last_name}${value.created_first_name}`
      case EXPORTS_TYPE[2].type:
        return `${value.created_last_name}${value.created_first_name}`
      default:
        return `${value.sharing_last_name}${value.sharing_first_name}`
    }
  }

  const handleItemClick = (value, exportTitle) => { 
    const state = {
      selectAll: true,
      openExportPopup: true,
      exportTitle: exportTitle,
    };
    
    return navAction(value, type, state);
  };

  const sortMeta = type === 'piecefolio' 
    ? [
        { field: 'piece_setting_id', order: 1 },
        { field: 'piece_id', order: -1 }         
      ]
    : [
        { field: 'shared_time', order: -1 }      
      ]

  return (
    <>
      <DataTable
        className={`${styles.tableWrapper}`}
        value={data}
        rowGroupMode='subheader'
        groupRowsBy={type == 'piecefolio' ? 'piece_setting_id' : 'shared_time'}
        scrollable
        rowGroupHeaderTemplate={type == 'piecefolio' ? headerPieceTemplate : headerTemplate}
        paginator={data.length > DEFAULT_ITEMS_PER_PAGE_SHARED}
        selectionMode='single'
        rowClassName={`${styles.topicRow}  row-table background-theme-hover`}
        onSelectionChange={(e) => navAction(e.value, type)}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onPage={handlePageChange}
        first={first1}
        rows={rows1}
        emptyMessage={NOT_FOUND}
        sortMode={type === 'piecefolio' ? 'multiple' : 'single'}
        // sortField={type == 'piecefolio' ? 'piece_setting_id' : 'shared_time'}
        sortField={type === 'piecefolio' ? undefined : 'shared_time'}
        // sortOrder={-1}
        sortOrder={type === 'piecefolio' ? undefined : -1}
        sortMeta={sortMeta}
        multiSortMeta={type === 'piecefolio' ? sortMeta : undefined}
      >
        {/* title Column */}
        <Column
          field='title'
          style={{ flex: 2 }}
          body={({ title }) => {
            return <ReadMore maxChar={120}>{title}</ReadMore>
          }}
        ></Column>

        {/* ShareName and Action Button */}
        <Column
            header=''
            body={(val) => (
              <div className={styles.actionCell}>
                {/* Share Name */}
                <Box className={styles.boxSender}>
                  <ReactSVG src={userSquareIcon} />
                  <p className='truncate' style={{ width: 80 }}>
                    {getShareName(val)}
                  </p>
                </Box>

                {/* Action Buttons */}
                <Box className={styles.boxActions}>
                  <ul className='bg-theme-hover-opacity'>
                    {/* Copy Action */}
                    {type!=EXPORTS_TYPE[0].type && (
                      <li 
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/${type}/add-new?copy=${getValueId(val, type)}`)
                        }} 
                        className={styles.action}
                      >
                        <Box className={`${styles.boxAction}`}>
                          <ContentCopyIcon />
                          <p>コピー</p>
                        </Box>
                      </li>
                    )}

                    {/* Export Action */}
                    <li
                      className={styles.action}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(e, val)
                      }}
                    >
                      <Box className={styles.boxAction}>
                        <img src={ACTIONS[1].icon} alt="Export" />
                        <p>出力</p>
                      </Box>
                      {dropdownOpen === getValueId(val, type) && (
                        <ul className={`${styles.dropdownMenu} ${data.share_permission != PERMISSION_VIEW ? styles.dropdownMenuShifted : styles.dropdownMenuAligned}`} style={{ top: dropdownPosition.top, bottom: dropdownPosition.bottom }}>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleItemClick(val, EXPORTS[0].label);
                            }}
                          >
                              <img src={EXPORTS[0].icon} alt={EXPORTS[0].label} />
                              <p>{EXPORTS[0].label}</p>
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleItemClick(val, EXPORTS[1].label);
                            }}
                          >
                              <img src={EXPORTS[1].icon} alt={EXPORTS[1].label} />
                              <p>{EXPORTS[1].label}</p>
                          </li>
                        </ul>
                      )}
                    </li>

                    {/* Delete Action */}
                    {title === SELF || val.share_permission != PERMISSION_VIEW ? (
                      <li 
                        onClick={(e) => {
                          e.stopPropagation();
                          openDialogConfirm(val)
                        }}
                        className={styles.action}
                      >
                        <Box className={`${styles.boxAction}`}>
                          <ReactSVG src={trashIcon} />
                          <p>削除</p>
                        </Box>
                      </li>
                    ) : null}
                  </ul>
                </Box>
              </div>
            )}
            className={styles.shareActionsColumn}
          ></Column>
      </DataTable>

      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このベンチマーク調査を削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </>
  )
}
