import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import {
  ACTIONS,
  EXPORTS,
  PERMISSION_VIEW,
  FORMAT_DATE_CAPITAL,
  DEFAULT_ITEMS_PER_PAGE_RESEARCH,
} from '@/utils/constant'
import moment from 'moment'
import { SharingDialog, ConfirmDialog, ReadMore } from '@/components'
import { getMembersToShare, getSharedMembers } from '@/redux/modules/share'
import { useDispatch, useSelector } from 'react-redux'
import { onDownloadTopic } from '@/utils/function'
import chatApi from '@/api/chat'
import toast from 'react-hot-toast'
import { deleteTopic, chatSelector, setPages } from '@/redux/modules/chat'
import { userSquareIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'

export default function HistoryDataTable({ data = [], title, showCreator = false }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isProcessing, alertMessage, pages } = useSelector(chatSelector)

  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const [topicSelected, setTopicSelected] = useState()
  const [openDialog, setOpenDialog] = useState(false)

  const [first1, setFirst1] = useState(0)
  const [rows1, setRows1] = useState(DEFAULT_ITEMS_PER_PAGE_RESEARCH)
  
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 'auto', bottom: 'auto' })
 
  const handleCloseSharingDialog = (e) => {
    e.stopPropagation()
    setShowSharingDialog(false)
  }

  const openSharingDialog = (topic) => {
    setShowSharingDialog(true)
    setTopicSelected(topic)
    dispatch(getMembersToShare({ topicId: topic.topic_id }))
    dispatch(getSharedMembers({ topicId: topic.topic_id }))
  }

  const onDownload = async (topic) => {
    const controller = new AbortController()
    const signal = controller.signal
    try {
      const res = await chatApi.getTopic({ topicId: topic.topic_id, signal: signal })
      if (res?.topic_id) onDownloadTopic(res)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleDelete = () => {
    dispatch(deleteTopic({ topicId: topicSelected.topic_id, key: title }))
  }

  const openDialogConfirm = (topic) => {
    setOpenDialog(true)
    setTopicSelected(topic)
  }

  const toggleDropdown = (e, topicId) => {
    e.stopPropagation()

    const elementRect = e.currentTarget.getBoundingClientRect()
    const dropdownHeight = 104

    const spaceBelow = window.innerHeight - elementRect.bottom
    const shouldOpenAbove = spaceBelow < dropdownHeight

    setDropdownPosition({
      top: shouldOpenAbove ? 'auto' : `${elementRect.height}px`,
      bottom: shouldOpenAbove ? `${elementRect.height}px` : 'auto',
    })

    setDropdownOpen(dropdownOpen === topicId ? null : topicId)
  }


  const headerTemplate = (value) => {
    const currenDate = moment().startOf('day')
    const daysDiff = currenDate.diff(moment(value.created_time, [FORMAT_DATE_CAPITAL]), 'days')
    const groupTitle = daysDiff === 0 ? '今日' : daysDiff === 1 ? '昨日' : value.created_time

    return (
      <div className='flex align-items-center gap-2'>
        <span className='font-bold'>{groupTitle}</span>
      </div>
    )
  }

  const handlePageChange = (event) => {
    const { page, rows } = event
    dispatch(setPages({ title: title, currentPage: page + 1, rows: rows }))
  }

  useEffect(() => {
    if (alertMessage) {
      setOpenDialog(false)
    }
  }, [alertMessage])

  useEffect(() => {
    if (pages.length) {
      pages.map((page) => {
        if (page.title === title) {
          const first = page.currentPage ? page.rows * (page.currentPage - 1) : 0
          setFirst1(first)
          setRows1(page.rows)
        }
      })
    }
  }, [pages])

  return (
    <>
      <DataTable
        className={`${styles.tableWrapper} history_table`}
        value={data}
        rowGroupMode='subheader'
        groupRowsBy='created_time'
        sortMode='single'
        sortField='created_time'
        sortOrder={-1}
        scrollable
        rowGroupHeaderTemplate={headerTemplate}
        paginator={data.length > DEFAULT_ITEMS_PER_PAGE_RESEARCH}
        selectionMode='single'
        rowClassName={`${styles.topicRow} background-theme-hover`}
        onSelectionChange={(e) => navigate(`/search/${e.value.topic_id}`)}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onPage={handlePageChange}
        first={first1}
        rows={rows1}
      >
        <Column
          field='title'
          style={{ flex: 2 }}
          body={({ title }) => {
            return <ReadMore>{title}</ReadMore>
          }}
        ></Column>
        <Column
          field='actions'
          body={(val) => {
            return (
              <Box className={styles.boxActions}>
                <ul className='bg-theme-hover-opacity'>
                  {val.share_permission != PERMISSION_VIEW && (
                    <li
                      onClick={(e) => {
                        e.stopPropagation()
                        openSharingDialog(val)
                      }}
                      className={`action`}
                    >
                      <Box className={styles.boxAction}>
                        <img src={ACTIONS[0].icon} />
                        <p>共有</p>
                      </Box>
                    </li>
                  )}
                  <li
                    className={`action `}
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleDropdown(e, val.topic_id)
                    }}
                  >
                    <Box className={styles.boxAction}>
                      <img src={ACTIONS[1].icon} />
                      <p>出力</p>
                    </Box>
                    {dropdownOpen === val.topic_id && (
                      <ul
                        className={styles.dropdownMenu}
                        style={{ top: dropdownPosition.top, bottom: dropdownPosition.bottom }}
                      >
                        <li
                          onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/search/${val.topic_id}`, {
                              state: {
                                selectAll: true,
                                openExportPopup: true,
                                exportTitle: EXPORTS[0].label,
                              },
                            })
                          }}
                        >
                          <img src={EXPORTS[0].icon} />
                          <p>{EXPORTS[0].label}</p>
                        </li>
                        <li
                          onClick={(e) => {
                            e.stopPropagation()
                            navigate(`/search/${val.topic_id}`, {
                              state: {
                                selectAll: true,
                                openExportPopup: true,
                                exportTitle: EXPORTS[1].label,
                              },
                            })
                          }}
                        >
                          <img src={EXPORTS[1].icon} />
                          <p>{EXPORTS[1].label}</p>
                        </li>
                      </ul>
                    )}
                  </li>
                  {val.share_permission != PERMISSION_VIEW && (
                    <li
                      onClick={(e) => {
                        e.stopPropagation()
                        openDialogConfirm(val)
                      }}
                      className={`action`}
                    >
                      <Box className={styles.boxAction}>
                        <img src={ACTIONS[2].icon} />
                        <p>削除</p>
                      </Box>
                    </li>
                  )}
                </ul>
              </Box>
            )
          }}
          className={styles.colActions}
        ></Column>
        {showCreator && (
          <Column
            field='creator'
            body={(val) => {
              return (
                <Box className={styles.boxSender}>
                  <ReactSVG src={userSquareIcon} />
                  <p className='truncate' style={{ width: 80 }}>
                    {`${val.created_last_name || ''}${val.created_first_name || ''}`}
                  </p>
                </Box>
              )
            }}
          ></Column>
        )}
      </DataTable>
      {topicSelected && (
        <SharingDialog
          show={showSharingDialog}
          handleClose={handleCloseSharingDialog}
          topicId={topicSelected.topic_id}
          title={`${topicSelected.title}`}
        />
      )}
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このトピックを削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </>
  )
}
