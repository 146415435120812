import axiosClient from './axiosClient';
import { TIMEOUT } from '@/utils/constant';

const pieceApi = {
    getPieceSetting: async (data) => {
        const url = '/piece/setting'; 

        try {
        const response = axiosClient.get(url, { signal: data.signal });
        return response;
        } catch (error) {
        const errorMessage = error.response?.data?.error || '情報を取得できませんでした';
        throw new Error(errorMessage);
        }
    },
    pieceSetting: async (data) => {
        const url = '/piece/setting'; 

        try {
        const response = axiosClient.post(url, data);
        return response;
        } catch (error) {
        const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
        throw new Error(errorMessage);
        }
    },
    sharePiece: async (data) => {
        const url = '/piece/share'; 

        try {
        const response = axiosClient.post(url, data);
        return response;
        } catch (error) {
        const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
        throw new Error(errorMessage);
        }
    },
    getAllPiece: async (data) => {
        const url = `/piece/all/${data.piece_setting_id}`; 

        try {
            const response = axiosClient.get(url, { signal: data.signal });
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    getShareList: async (data) => {
        const url = `/piece/shared_list`; 

        try {
            const response = axiosClient.get(url, { signal: data.signal });
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    createPiece: async (data) => {
        const url = `/piece`; 

        try {
            const response = axiosClient.post(url, data);
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    editPiece: async (data) => {
        const url = `/piece`; 

        try {
            const response = axiosClient.post(url, data);
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    deletePiece: async (data) => {
        const url = `/piece/${data.pieceId}`; 

        try {
            const response = axiosClient.delete(url);
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    getPiece: async (data) => {
        const url = `/piece/${data.piece_id}`; 

        try {
            const response = axiosClient.get(url, { signal: data.signal });
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    getPieceLinks: async (data) => {
        const url = `/piece/link/${data.piece_id}`; 

        try {
            const response = axiosClient.get(url, { signal: data.signal });
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
            throw new Error(errorMessage);
        }
    },
    regeneratePiece: async (data) => {
        const url = `/piece/regenerate`; 
        try {
            const response = axiosClient.post(url, data);
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'ピースの再生成に失敗しました';
            throw new Error(errorMessage);
        }
    },
    getMembersToShare: async (data) => {
        const url = '/user/to-share-piece'
        return axiosClient.get(url, { params: data })
    },
    getMembersShared: async (data) => {
        const url = '/user/shared-piece'
        return axiosClient.get(url, { params: data })
    },
    getSharedPieces: async (data) => {
        const url = `/piece/list_shared_pieces`;

        try {
            const response = axiosClient.get(url, { signal: data.signal });
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.error || '共有されたピースのリストを取得できませんでした';
            throw new Error(errorMessage);
        }
    },
};

export default pieceApi;
