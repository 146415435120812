import React, { useState, useRef } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import toast from 'react-hot-toast';
import styles from './upload.module.css'
import templateApi from '@/api/template'
import Label from '../Label';
import { saveTemplateLib, saveTemplateBMPiece } from '@/redux/modules/manager'
import { useSelector, useDispatch } from 'react-redux'

const FileUploadComponent = React.forwardRef(({ label = '', type = '', fileNameExist='',required = false, ...props }, ref) => {
  const dispatch = useDispatch()

  const [pptxFile, setPptxFile] = useState(null); 
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const handlePptxUpload = (event) => {
    const file = event.target.files?.[0];
    if (file && (file.name.endsWith('.ppt') || file.name.endsWith('.pptx'))) {
      setPptxFile(file);
      setError('');
      handleFileSubmit(file, type );
    } else {
      setPptxFile(null);
      setError('無効なファイル形式です。 .ppt または .pptx ファイルをアップロードしてください。');
    }
  };

  const handleFileSubmit = async (file, type) => {
    if (!file) {
      setError('アップロードするファイルを選択してください');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      if (type==='library') {
        const response = await templateApi.uploadTemplate(formData);
        dispatch(saveTemplateLib(response.data))
      } else {
        const response = await templateApi.uploadTemplateBenchmark(formData);
        dispatch(saveTemplateBMPiece(response.data))
      }

      toast.success('テンプレートのアップロードに成功しました！');
    } catch (error) {
      console.error('Error during file upload:', error.response?.data || error);
      toast.error(error.response?.data || error || 'テンプレートのアップロード中にエラーが発生しました。 もう一度やり直してください。');
      clearFileInput();
    } finally {
      setLoading(false);
    }
  };

  const clearFileInput = () => {
    setPptxFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const renderFileName = () => {
    if (loading) {
      return <CircularProgress size={24} />;
    }
    if (pptxFile) {
      return pptxFile.name;
    }
    if (fileNameExist) {
      return fileNameExist;
    }
    return (
      <>
        <CloudUploadIcon sx={{ mr: 2 }} /> ファイルをアップロード
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
      <Label  label={label} />

      <div className={styles.uploadDiv}>
        <Button
          variant="outlined"
          component="label"
          onClick={handleButtonClick}
          fullWidth
          disabled={loading}
          sx={{
            height: '60px',
            justifyContent: 'center',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            borderColor: '#d3d3d3',
          }}
        >
          {renderFileName()}
        </Button>

        <input
          type="file"
          ref={inputRef}
          hidden
          accept=".ppt,.pptx"
          onChange={handlePptxUpload}
        />

        {error && (
            <Typography color="error" sx={{ mt: 1 }}>
            {error}
            </Typography>
        )}

        <Typography sx={{ mt: 1, ml: 2, color: 'red', fontSize: '12px' }}>
            ※ 対象形式: 会社のロゴとヘッダーが含まれたppt/pptx形式のファイルでご提供ください。
        </Typography>
      </div>
    </Box>
  );
})

export default FileUploadComponent;
