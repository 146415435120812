import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import styles from './export.module.css'
import InputAdornment from '@mui/material/InputAdornment'
import useTheme from '@/hooks/useTheme'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import { EXPORTS, EXPORTS_TYPE } from '@/utils/constant'

function ExportPopup({
  open,
  onClose,
  exportTitle,
  selectedList,
  onDownload,
  onDownloadFromBE,
  type,
  fileNameDefault,
  onSelectAll,
  onTitleClick,
  isSelectAll,
}) {
  const getKeyByType = (item) => {
    switch (type) {
      case EXPORTS_TYPE[0].type:
        return item.question_id
      case EXPORTS_TYPE[1].type:
        return item.benchmark_link_id
      case EXPORTS_TYPE[2].type:
        return item.piece_link_id
      default:
        return item.piece_link_id
    }
  }

  const [fileName, setFileName] = useState(fileNameDefault ? fileNameDefault : 'infolio-export')
  const [editedTitles, setEditedTitles] = useState({})
  const [allSelected, setAllSelected] = useState(false)
  const [orderedList, setOrderedList] = useState(selectedList)
  const [exportDisabled, setExportDisabled] = useState(true)

  useEffect(() => {
    if (fileNameDefault) {
      setFileName(fileNameDefault)
    }
  }, [fileNameDefault])

  useEffect(() => {
    setOrderedList(selectedList)

    const titles = selectedList.reduce((acc, item) => {
      const key = getKeyByType(item)
      acc[key] = item.question || item.title || ''
      return acc
    }, {})
    setEditedTitles(titles)
  }, [selectedList])

  useEffect(() => {
    const isListEmpty = orderedList.length === 0
    const isFileNameEmpty = fileName.trim() === ''
    const hasEmptyTitle = orderedList.some(
      (item) => (editedTitles[getKeyByType(item)] || '').trim() === ''
    )

    setExportDisabled(isListEmpty || isFileNameEmpty || hasEmptyTitle)
  }, [orderedList, fileName, editedTitles, type])

  useEffect(() => {
    if (orderedList.length > 0 && selectedList.length === orderedList.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [selectedList, orderedList])

  useEffect(() => {
    if (isSelectAll) {
      handleSelectAll();
    }
  }, [isSelectAll]);

  if (!open) return null

  const handleFileNameChange = (e) => {
    let input = e.target.value
    const extension = '.pptx'

    if (input.endsWith(extension)) {
      input = input.slice(0, -extension.length).replace(/\./g, '') + extension
    } else {
      input = input.replace(/\./g, '')
    }

    setFileName(input)
  }

  const handleTitleChange = (id, newTitle) => {
    setEditedTitles((prevState) => ({
      ...prevState,
      [id]: newTitle,
    }))
  }

  const formatSelectedItems = () => {
    return orderedList.map((item) => ({
      id: getKeyByType(item),
      title: editedTitles[getKeyByType(item)],
    }))
  }

  const handleExport = () => {
    const payload = {
      file_name: fileName,
      selected_ids: formatSelectedItems(),
    }

    exportTitle === EXPORTS[0].label ? onDownload(payload) : onDownloadFromBE(payload)
  }

  const handleSelectAll = () => {
    setAllSelected((prev) => !prev)
    onSelectAll(!allSelected)
  }

  const handleDragEnd = (result) => {
    const { destination, source } = result

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const reordered = Array.from(orderedList)
    const [movedItem] = reordered.splice(source.index, 1)
    reordered.splice(destination.index, 0, movedItem)

    setOrderedList(reordered)
  }

  return (
    <Box className={styles.drawerContent}>
      <Button variant='text' onClick={onClose} className={styles.closeButton}>
        <img src={useTheme().closeIcon} alt='Close' />
      </Button>

      <label className={styles['color-theme']}>{exportTitle}</label>

      <Box className={styles.subTitle}>
        <span className={styles['drawer-sub-title']}>ファイル名</span>
      </Box>
      <Box className={styles['drawer-filename']}>
        <TextField
          multiline
          value={fileName}
          variant='standard'
          onChange={handleFileNameChange}
          className={styles['fileNameInput']}
          InputProps={{
            endAdornment: <InputAdornment position='end'>.pptx</InputAdornment>,
          }}
          sx={{ '& .MuiInputBase-root': { minHeight: 'fit-content' } }}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
        />
      </Box>

      <Box className={styles.subTitle}>
        <span className={styles['drawer-sub-title']}>スライドタイトル名</span>
        <Button className={styles['btn-color-theme']} onClick={handleSelectAll}>
          {allSelected ? '全ての選択を解除' : '全てを選択'}
        </Button>
      </Box>
      <Box className={styles['drawer-listing']}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='questionsDroppable'>
            {(provided) => (
              <List
                className={styles['list-item']}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {orderedList.length > 0 ? (
                  orderedList.map((item, index) => (
                    <Draggable
                      key={getKeyByType(item)}
                      draggableId={getKeyByType(item).toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          key={getKeyByType(item)}
                          className={styles['questionItem']}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            background: snapshot.isDragging ? '#f0f0f0' : 'white',
                          }}
                        >
                          <Box
                            className={styles.titleContainer}
                            onClick={() => onTitleClick(getKeyByType(item))}
                            style={{ cursor: 'pointer', flexGrow: 1 }}
                            role='button'
                            tabIndex={0}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                onTitleClick(getKeyByType(item))
                              }
                            }}
                          >
                            <TextField
                              className={'export-textField'}
                              variant='standard'
                              multiline
                              value={editedTitles[getKeyByType(item)] || ''}
                              onChange={(e) =>
                                handleTitleChange(getKeyByType(item), e.target.value)
                              }
                              fullWidth
                              inputProps={{
                                style: {
                                  padding: 0,
                                },
                              }}
                              sx={{ '& .MuiInputBase-root': { minHeight: 'fit-content' } }}
                            />
                          </Box>

                          <IconButton
                            {...provided.dragHandleProps}
                            edge='start'
                            aria-label='drag handle'
                            className={styles.dragHandle}
                            size='small'
                            sx={{ marginRight: 1, cursor: 'grab' }}
                          >
                            <MenuIcon />
                          </IconButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p>質問が選択されていません。</p>
                )}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Button
        variant='contained'
        disabled={exportDisabled}
        className={`${styles['drawerButton']}`}
        onClick={handleExport}
        sx={{
          '&.Mui-disabled': {
            background: 'rgb(0 0 0 / 60%)',
            color: '#CACACA',
          },
        }}
      >
        PPTXを出力する
      </Button>
    </Box>
  )
}

export default ExportPopup
