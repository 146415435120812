import axiosClient from './axiosClient';
import { TIMEOUT } from '@/utils/constant';

const templateApi = {
  checkFileExists: async () => {
    const url = '/template';
    return axiosClient.get(url, { timeout: TIMEOUT });
  },
  uploadTemplate: async (data) => {
    const url = '/template/save'; 

    try {
      const response = axiosClient.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: TIMEOUT,
      });
      return response;
    } catch (error) {
      console.error('Error during file download:', error.response?.data || error);
      const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
      throw new Error(errorMessage);
    }
  },
  updateTemplate: async (data) => {
    const url = '/template/change'; 
    try {
      const response = await axiosClient.post(url, data, {
        timeout: TIMEOUT,
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      console.error('Error during file update:', error.response?.data || error);
      const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
      throw new Error(errorMessage);
    }
  },
  downloadTemplate: async (data) => {
    const url = '/template/download';
    
    try {
      const response = await axiosClient.post(url, data, {
        timeout: TIMEOUT,
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      console.error('Error during file download:', error.response?.data || error);
      const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
      throw new Error(errorMessage);
    }
  },

  // Benchmark
  uploadTemplateBenchmark: async (data) => {
    const url = '/template_benchmark/save'; 
    try {
      const response = axiosClient.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: TIMEOUT,
      });

      return response;
    } catch (error) {
      console.error('Error during file upload:', error.response?.data || error);
      const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
      throw new Error(errorMessage);
    }
  },
  downloadTemplateBenchmark: async (data) => {
    const url = '/template_benchmark/download';
    
    try {
      const response = await axiosClient.post(url, data, {
        timeout: TIMEOUT,
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      console.error('Error during file download:', error.response?.data || error);
      const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
      throw new Error(errorMessage);
    }
  },

  // Piecefolio
  downloadTemplatePiece: async (data) => {
    const url = '/template_piece/download';
    
    try {
      const response = await axiosClient.post(url, data, {
        timeout: TIMEOUT,
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      console.error('Error during file download:', error.response?.data || error);
      const errorMessage = error.response?.data?.error || 'ファイルのダウンロードに失敗しました';
      throw new Error(errorMessage);
    }
  },
};

export default templateApi;
