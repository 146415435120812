import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import templateApi from '@/api/template'
import { catchError } from '@/utils/errorHandling'
import { PERMISSION_OWNER, PERMISSION_DELETE } from '@/utils/constant'


export const uploadTemplate = createAsyncThunk('template/uploadTemplate', async (data, thunkAPI) => {
  try {
    let res = await templateApi.uploadTemplate(data)
    if (!res?.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})
export const uploadTemplateBenchmark = createAsyncThunk('template/uploadTemplateBenchmark', async (data, thunkAPI) => {
    try {
      let res = await templateApi.uploadTemplateBenchmark(data)
      if (!res?.errors) {
        return res
      }
    } catch (e) {
      return catchError(e, thunkAPI, data.signal)
    }
  })


export const managerSlice = createSlice({
  name: 'manager',
  initialState: {
    library_template: null,
    benchmark_template: null,
  },
  reducers: {
    clearState: (state) => {
      state.library_template = null
      state.library_template = null
      return state
    },
    saveTemplateLib: (state, action) => {
      state.library_template = action.payload;
    },
    saveTemplateBMPiece: (state, action) => {
        state.benchmark_template = action.payload;
      },
  },
  extraReducers: {
    [uploadTemplate.fulfilled]: (state, { payload }) => {
        state.library_template = payload
        state.isFetching = false
      },
    [uploadTemplate.rejected]: (state, { payload }) => {
        if (payload) {
            state.isError = true
            state.errors = payload.errors
        }
        state.isFetching = false
    },
    [uploadTemplateBenchmark.fulfilled]: (state, { payload }) => {
        state.benchmark_template = payload
        state.isFetching = false
      },
    [uploadTemplateBenchmark.rejected]: (state, { payload }) => {
        if (payload) {
            state.isError = true
            state.errors = payload.errors
        }
        state.isFetching = false
    },
  },
})

export const { clearState, saveTemplateLib, saveTemplateBMPiece } = managerSlice.actions

export const selectTemplateLib = (state) => state.manager.library_template
export const selectTemplateBM = (state) => state.manager.benchmark_template

export const managerSelector = (state) => state.manager
