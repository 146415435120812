import {
  circleUser,
  circleUserGreen,
  circleUserPurple,
  arrowDownIcon,
  arrowDownIconGreen,
  arrowDownIconPurple,
  userBlueIcon,
  userIconGreen,
  userIconPurple,
  usersIcon,
  usersIconGreen,
  usersIconPurple,
  sharePurpleIcon,
  exportPurpleIcon,
  editPurpleIcon,
  searchPurpleIcon,
  shareBlueIcon,
  exportBlueIcon,
  editBlueIcon,
  searchBlueIcon,
  shareGreenIcon,
  exportGreenIcon,
  editGreenIcon,
  searchGreenIcon,
  radioButtonCheckedPurple,
  radioButtonCheckedGreen,
  radioButtonCheckedBlue,
  menuDotsGreenIcon,
  menuDotsPurpleIcon,
  menuDotsBlueIcon,
  userSquarePurpleIcon,
  userSquareBlueIcon,
  userSquareGreenIcon,
  codeForkBlueIcon,
  codeForkGreenIcon,
  codeForkPurpleIcon,
  keyBlueIcon,
  keyGreenIcon,
  keyPurpleIcon,
  checkedBoxGreenIcon,
  checkedBoxBlueIcon,
  checkedBoxPurpleIcon,
  managerBlueIcon,
  managerGreenIcon,
  managerPurpleIcon,
  closeBlueIcon,
  closeGreenIcon,
  closePurpleIcon
} from '@/utils/images'

export const PURPLE_ICONS = {
  userIcon: circleUserPurple,
  user1Icon: userIconPurple,
  users: usersIconPurple,
  arrowDown: arrowDownIconPurple,
  shareIcon: sharePurpleIcon,
  exportIcon: exportPurpleIcon,
  editIcon: editPurpleIcon,
  searchIcon: searchPurpleIcon,
  radioChecked: radioButtonCheckedPurple,
  menuDotsIcon: menuDotsPurpleIcon,
  userSquareIcon: userSquarePurpleIcon,
  keyIcon: keyPurpleIcon,
  codeForkIcon: codeForkPurpleIcon,
  checkedBoxIcon: checkedBoxPurpleIcon,
  managerIcon: managerPurpleIcon,
  closeIcon: closePurpleIcon
}
export const GREEN_ICONS = {
  userIcon: circleUserGreen,
  user1Icon: userIconGreen,
  users: usersIconGreen,
  arrowDown: arrowDownIconGreen,
  shareIcon: shareGreenIcon,
  exportIcon: exportGreenIcon,
  editIcon: editGreenIcon,
  searchIcon: searchGreenIcon,
  radioChecked: radioButtonCheckedGreen,
  menuDotsIcon: menuDotsGreenIcon,
  userSquareIcon: userSquareGreenIcon,
  keyIcon: keyGreenIcon,
  codeForkIcon: codeForkGreenIcon,
  checkedBoxIcon: checkedBoxGreenIcon,
  managerIcon: managerGreenIcon,
  closeIcon: closeGreenIcon

}
export const BLUE_ICONS = {
  userIcon: circleUser,
  user1Icon: userBlueIcon,
  users: usersIcon,
  arrowDown: arrowDownIcon,
  shareIcon: shareBlueIcon,
  exportIcon: exportBlueIcon,
  editIcon: editBlueIcon,
  searchIcon: searchBlueIcon,
  radioChecked: radioButtonCheckedBlue,
  menuDotsIcon: menuDotsBlueIcon,
  userSquareIcon: userSquareBlueIcon,
  keyIcon: keyBlueIcon,
  codeForkIcon: codeForkBlueIcon,
  checkedBoxIcon: checkedBoxBlueIcon,
  managerIcon: managerBlueIcon,
  closeIcon: closeBlueIcon

}
