import React, { useState, useEffect } from 'react'
import {
  FileUploadComponent
} from '@/components'
import { Button, Box, Typography } from '@mui/material';
import styles from './manager.module.css'
import toast from 'react-hot-toast';
import { getUser } from '@/utils/localData'
import { ADMIN } from '@/utils/constant'
import { useNavigate } from 'react-router-dom'
import { getPieceSetting } from '@/redux/modules/piecefolio'
import { selectTemplateLib, selectTemplateBM, saveTemplateLib, saveTemplateBMPiece } from '@/redux/modules/manager'
import { useSelector, useDispatch } from 'react-redux'
import templateApi from '@/api/template';
import PieceSettingItem from './PieceSettingItem';
import { NOT_FOUND } from '@/utils/message'
// import SendMail from './SendMailDialog'

function index() {
  const currentUser = getUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const dummy = {
    title: 'タイトル',
    sitesData: ['www.website.com', 'www.mypage.com'],
    keywordsData: ['Keyword', 'word'],
  }

  const templateFromStore = useSelector(selectTemplateLib)
  const templateBMFromStore = useSelector(selectTemplateBM)

  const [showSendMailDialog, setShowSendMailDialog] = useState(false)
  const [pieceSettings, setPieceSettings] = useState([]); 
  const [sites, setSites] = useState([]); 
  const [keywords, setKeywords] = useState([]); 
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileExists, setFileExists] = useState(false);

  const onToggleSendMailDialog = (e) => {
    setShowSendMailDialog(!showSendMailDialog)
  }

  const handleCloseSendMailDialog = (e) => {
    e.stopPropagation()
    setShowSendMailDialog(false)
  }

  useEffect(() => {
    const controller = new AbortController()

    const checkFileExists = async () => {
        const response = await templateApi.checkFileExists();
        dispatch(saveTemplateLib(response.library_template))
        dispatch(saveTemplateBMPiece(response.benchmark_template))
    }

    checkFileExists() 

    return () => {
      controller.abort()
    }
  }, []);

  useEffect(() => {
    if (currentUser && currentUser?.roleId != ADMIN) {
      navigate('/aisearch')
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const fetchData = async () => {
      try {
        const result = await dispatch(getPieceSetting({ signal: controller.signal })).unwrap()
       
        if (result) {
          setPieceSettings(result);
        }
      } catch (error) {
        console.error('Failed to fetch piece setting:', error)
      }
    }

    fetchData()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    setFileExists({
      library_template: templateFromStore,
      benchmark_template: templateBMFromStore
    })
  }, [templateFromStore, templateBMFromStore])

  return (
    <div className={styles.container}>
      <div className={styles.managerHeader}>
        <label className={styles['color-theme']}>
          企業管理設定
        </label>
      </div>

      <Box>
        {/* <div>
          <div className={styles.pieceHeader}>
            <Typography variant="h6" gutterBottom>
              PieceFolio設定
            </Typography>
          </div>

          <Box mb={2}>
            {pieceSettings.length > 0 ? (
              pieceSettings.map((setting, index) => (
                <PieceSettingItem
                  key={setting.piece_setting_id}
                  title={setting.title}
                  sitesData={setting.sites}
                  keywordsData={setting.keywords}
                />
              ))) : (
                <p className={styles.notFoundText}>{NOT_FOUND}</p>
              )
            }
          </Box>
        </div> */}

        <div>
          <Typography variant="h6" gutterBottom>
            PPTXのテンプレート設定
          </Typography>

          <FileUploadComponent label="検索" type="library" fileNameExist={fileExists.library_template}/>
          <FileUploadComponent label="ベンチマーク分析用" type="benchmark" fileNameExist={fileExists.benchmark_template}/>
        </div>
      </Box>

      {/* <SendMail
        show={showSendMailDialog}
        handleClose={handleCloseSendMailDialog}
        title={"メールを送信"}
      /> */}
    </div>
  );
};

export default index;
